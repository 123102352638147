<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Phenylamine, <chemical-latex content="C6H5NH2" /> has a
        <stemble-latex content="$\text{pK}_\text{b}$" /> of 9.13.
      </p>

      <p class="mb-2">a) Calculate the base ionization constant for phenylamine.</p>

      <calculation-input
        v-model="inputs.Kb"
        class="mb-5"
        prepend-text="$\text{K}_\text{b}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What will be the concentration of the neutral form of a
        <number-value :value="concInit" unit="\text{mM}" /> solution of
        <chemical-latex content="C6H5NH2" /> at equilibrium?
      </p>

      <calculation-input
        v-model="inputs.concA"
        class="mb-5"
        prepend-text="$\ce{[C6H5NH2]}_\text{eq}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">c) What is the concentration of the conjugate acid at equilibrium?</p>

      <calculation-input
        v-model="inputs.concHA"
        class="mb-5"
        prepend-text="$\ce{[C6H5NH3+]}_\text{eq}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">d) What is the pOH of this solution?</p>

      <calculation-input
        v-model="inputs.pOH"
        class="mb-5"
        prepend-text="$\text{pOH:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">e) What is the pH of the solution?</p>

      <calculation-input
        v-model="inputs.pH"
        class="mb-0"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'Question465',
  components: {
    NumberValue,
    ChemicalNotationInput,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Kb: null,
        concA: null,
        concHA: null,
        pOH: null,
        pH: null,
      },
    };
  },
  computed: {
    concInit() {
      return this.taskState.getValueBySymbol('concInit').content;
    },
  },
};
</script>
